export const assetApiPath = (v: string): string => {
  if (v) {
    return `${process.env.REACT_APP_API_URL}/${v}`;
  }
  return null;
};

export const revertAssetApiPath = (v: string): string => {
  if (v) {
    return v.replace(`${process.env.REACT_APP_API_URL}/`, '');
  }
  return null;
};

export const assetPath = (v: string): string => {
  if (v) {
    return require(`@shared/assets${v}`).default;
  }

  return null;
};
