import React from 'react';
import { AsideMenuItemWithSub } from '@shared/metronic/layout/components/aside/AsideMenuItemWithSub';
import { AsideMenuItem } from '@shared/metronic/layout/components/aside/AsideMenuItem';
import { useTranslation } from 'react-i18next';

export function Menu() {
  const { t } = useTranslation(['common']);

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/menu/dashboard.svg"
        title={t('common:aside_menu.dashboard', 'Dashboard')}
        fontIcon=""
      />
      <AsideMenuItemWithSub
        to="/websites"
        title={t('common:aside_menu.websites', 'Websites')}
        icon="/menu/websites.svg"
        fontIcon=""
      >
        <AsideMenuItem
          to="/websites/list"
          title={t('common:aside_menu.websites_list', 'List')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/websites/new"
          title={t('common:aside_menu.websites_new', 'New')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/widgets"
        title={t('common:aside_menu.widgets', 'Widgets')}
        icon="/menu/widgets.svg"
        fontIcon=""
      >
        <AsideMenuItem
          to="/widgets/list"
          title={t('common:aside_menu.widgets_list', 'List')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/widgets/new"
          title={t('common:aside_menu.widgets_new', 'New')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/finances"
        title={t('common:aside_menu.finances', 'Finances')}
        icon="/menu/finances.svg"
        fontIcon=""
      >
        <AsideMenuItem
          to="/finances/dashboard"
          title={t('common:aside_menu.finances_overview', 'Overview')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/finances/transactions-report"
          title={t('common:aside_menu.finances_transactions-report', 'Transactions report')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/finances/withdraw"
          title={t('common:aside_menu.finances_withdraw', 'Withdraw')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/finances/settings"
          title={t('common:aside_menu.finances_settings', 'Settings')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to={process.env.REACT_APP_HELP_CENTER_URL}
        icon="/menu/help-center.svg"
        title={t('common:aside_menu.help_center', 'Help Center')}
        fontIcon=""
        externalBlank
      />
    </>
  );
}
