import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '@app/modules/auth';
import loadingReducer from '@shared/features/loading/loadingSlice';
import notificationsReducer from '@shared/features/notifications/notificationsSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  loading: loadingReducer,
  notifications: notificationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([auth.saga()]);
}
