import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '@shared/metronic/partials';

export function PrivateRoutes() {
  const DashboardModule = lazy(() => import('@app/modules/dashboard/DashboardModule'));
  const InfoModule = lazy(() => import('@shared/modules/info/InfoModule'));
  const AccountModule = lazy(() => import('@app/modules/account/AccountModule'));
  const WebsitesModule = lazy(() => import('@app/modules/websites/WebsitesModule'));
  const WidgetsModule = lazy(() => import('@app/modules/widgets/WidgetsModule'));
  const FinancesModule = lazy(() => import('@app/modules/finances/FinancesModule'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardModule} />
        <Route path="/websites" component={WebsitesModule} />
        <Route path="/widgets" component={WidgetsModule} />
        <Route path="/account" component={AccountModule} />
        <Route path="/info" component={InfoModule} />
        <Route path="/finances" component={FinancesModule} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
