import React from 'react';
import style from './LoginWrapper.module.scss';
import { assetPath } from '@shared/utils/asset';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

const LoginWrapper = ({ children, social = false }) => {
  const { t } = useTranslation(['auth']);

  const handleSocialClick = (type) => {
    window.location.href = process.env.REACT_APP_API_URL + `/api/affiliate/auth/connect/${type}`;
  };

  return (
    <div className={style.wrapper}>
      <div className={style.wrapperContent}>{children}</div>
      {social && (
        <div className={style.wrapperSocial}>
          <p className="text-center text-white fs-14 lh-f14">
            {t('auth:social.title', 'or use your social accounts')}
          </p>
          <div className="d-flex mx-n4 justify-content-center mt-16">
            <button
              type="button"
              className={style.social}
              onClick={() => handleSocialClick('facebook')}
            >
              <span className="svg-icon svg-icon-12 svg-icon-white">
                <SVG src={assetPath('/icons/facebook.svg')} />
              </span>
            </button>
            <button
              type="button"
              className={style.social}
              onClick={() => handleSocialClick('google')}
            >
              <span className="svg-icon svg-icon-12 svg-icon-white">
                <SVG src={assetPath('/icons/google.svg')} />
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginWrapper;
