import axios from 'axios';
import { AuthModel } from '@app/modules/auth/models/AuthModel';
import { UserModel } from '@app/modules/auth/models/UserModel';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/affiliate/user/current-user`;
export const LOGIN_URL = `${API_URL}/api/affiliate/auth/login`;
export const REGISTER_URL = `${API_URL}/api/affiliate/auth/register`;
export const REGISTER_CONFIRM_URL = `${API_URL}/api/affiliate/auth/confirm-register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/api/affiliate/auth/reset-password`;

export function login(username: string, password: string) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(
  nick: string,
  email: string,
  password: string,
  repassword: string,
  regulations: boolean
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    nick,
    email,
    password: {
      password,
      repassword,
    },
    regulations,
    subscribeNewsletter: 0,
  });
}

export function registerConfirm(token: string) {
  return axios.put(`${REGISTER_CONFIRM_URL}/${token}`);
}

export function requestPassword(email: string) {
  return axios.post<{}>(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}
